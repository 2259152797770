import React from "react";

const RulesRegulation = () => {
  return (
    <>
      <div className="side-head">
        <h3>vellkie Rules and Regulations</h3>
      </div>
      <div className="announce-wrap">
        <p>vellkie Rules and Regulations</p>
        <p>
          NOTE:
          <br />
          Players using VPN and login from different IP frequently may result to
          void bets.
          <br />
          And on the basis of different IP from multiple city we can suspend the
          account and void bets.
          <br />
          All vellkie users including &lsquo;Super&rsquo;, &lsquo;Master&rsquo;
          and &lsquo;Sub&rsquo; account holders are advised to read the
          following &lsquo;Terms and Conditions&rsquo;. All users who use
          vellkie agree and accept to the following:
        </p>
        <p>
          The site www.vellkie.live and all of its original content are the sole
          property of &lsquo;Cric Infotech Ltd&rsquo; and are, as such, fully
          protected by International Copyright and other intellectual property
          rights laws.
          <br />
          Any form of &lsquo;Passing of funds&rsquo;, &lsquo;Self
          Matching&rsquo; will not be tolerated o vellkie. Any users found
          participating in such activites will be locked with the funds being
          reversed. Accounts participating in such activities must note that
          vellkie reserves the right to Void any bets of such nature at any time
          within 1 week of the bet being placed.
          <br />
          Any self matching ( punching ) bets on non favorite teams when
          liquidity is low will be voided even if the player account is in minus
          for the event , the upline will be responsible for the same.
          <br />
          Please note that if any account has been locked due to &lsquo;Passing
          of funds&rsquo; within the last 72 hours, vellkie reserves the right
          to void any bet of this nature within the account irrespective of when
          the bet was placed.
          <br />
          No Arguments or claim in the above context will be entertained by
          vellkie and the decision made by vellkie will stand as final.
          <br />
          vellkie Endeavors to have our services run 24 hours a day. However due
          to any technical issue, or disruption of services from our provider
          (Betfair.com), vellkie will not be liable for any market positions
          that any account holders may hold.
          <br />
          Betfair.com reserves the right to resettle or void any market for
          reasons such as technical delayed suspension from the provider. In any
          such case vellkie will settle according to Betfair.com
          <br />
          &lsquo;Super&rsquo; and &lsquo;Master&rsquo; account holders will be
          held responsible for the financial penalties incurred for any misuse
          or &lsquo;passing of funds&rsquo; from any of the &lsquo;Sub&rsquo;
          account holders under their accounts irregardless whether or not the
          financial positions of the sub accounts have been settled by their
          superior &lsquo;Super&rsquo; or &lsquo;Master&rsquo; holders.
          <br />
          In case anyone is found using 2 different IDs and logging in from same
          IP his winning in both accounts will be cancelled.
          <br />
          Any bets which are deemed of being suspicious, including bets which
          have been placed from the stadium or from a source at the stadium
          maybe voided at anytime. The decision of whether to void the
          particular bet in question or to void the entire market will remain at
          the discretion of vellkie. The final decision of whether bets are
          suspicious will be taken by vellkie and that decision will be full and
          final.
          <br />
          Any sort of cheating bet , any sort of Matching (Passing of funds),
          Court Siding (Ghaobaazi on commentary), Sharpening, Commission making
          is not allowed in vellkie, If any vellkie User is caught in any of
          such act then all the funds belonging that account would be seized and
          confiscated. No argument or claim in that context would be entertained
          and the decision made by vellkie management will stand as final
          authority.
          <br />
          Fluke hunting/Seeking is prohibited in vellkie, All the fluke bets
          will be reversed. Cricket commentary is just an additional feature and
          facility for vellkie user but vellkie is not responsible for any delay
          or mistake in commentary.
          <br />
          Members are not permitted to hold multiple accounts. This includes
          holding an account with any associated site operating on the same
          platform as this site. Where maximum bet or maximum market limits are
          imposed, the Site reserves to the right to void bets from accounts
          found to be exceeding these limits by using multiple accounts across
          this and any other associated sites.
          <br />
          Scalping and jobbing trades strictily prohibited in meta for all
          symbol.
          <br />
          Company reserve rights to void all profitable jobbing trades.
          <br />
          Manipulation and cheating (chamka) trade will be deleted any time.
          <br />
          Fresh limit and fresh stop loss not allowed &ndash; only allowed when
          you have existing holding.
          <br />
          Position will be stop out at 20% margin Level.
          <br />
          All the issues will be solved according to the Exchange decision.
          <br />
          Parking is strictly prohibited.
          <br />
          Trading is compulsory only standing not allowed.
          <br />
          Exchange Rules and Regulations
          <br />
          Part A - Introduction
          <br />
          Restricted territory information
          <br />
          Please be advised that our restricted territory information pertains
          to both residents and visitors in the restricted areas. Accounts
          registered in a non-restricted territory will become restricted if
          they are attempt to access and have betting activity from a territory
          which is restricted.
        </p>
        <p>
          Restricted territory countries are Algeria, Australia, Austria,
          Bulgaria, Canada, China, Cyprus, Czech Republic, Democratic
          People&rsquo;s Republic of Korea, Denmark, Europe, France (and French
          territories), Gibraltar, Germany, Iran, Iraq, Ireland, Italy, Japan,
          Malta, New Zealand, Poland, Portugal, Qatar, Romania, Singapore, South
          Africa, Spain, Turkey, United Kingdom and United States of America
          (and US territories)
        </p>
        <p>
          We have the right to void all players winnings if they are deemed to
          come from a restricted territory.
        </p>
        <p>
          1. Use and interpretation
          <br />
          The Exchange Rules and Regulations ("Exchange Rules") are part of
          terms and conditions.
        </p>
        <p>
          The Exchange Rules apply to all bets placed on Exchange markets. The
          Exchange Rules also apply to Exchange &rsquo;Multiples&rsquo; product
          (see the Multiples section below for further details). The Exchange
          Rules consist of the following:
        </p>
        <p>
          This INTRODUCTION section (Part A);
          <br />
          The GENERAL RULES (set out in Part B below);
          <br />
          The SPECIFIC SPORTS RULES (set out in Part C below &ndash; these apply
          to certain sports and to financial markets); and
          <br />
          The MARKET INFORMATION (located on every market either under the tab
          entitled "Rules" or under the &lsquo;Rules&rsquo;).
          <br />
          The General Rules apply to all bets unless stated otherwise in the
          Market Information or the Specific Sports Rules. If there is any
          inconsistency between the Specific Sports Rules and the General Rules,
          the Specific Sports Rules shall prevail. If there is any inconsistency
          between the Market Information and either the General Rules or the
          Specific Sports Rules, the Market Information shall prevail, except
          where the General Rules or Specific Sports Rules use the phrase
          'regardless of what it says in the Market Information' or similar
          wording.
        </p>
        <p>
          For any category or market not referred to in the Specific Sports
          Rules (e.g. &rsquo;Special Bets&rsquo; or beach volleyball), the
          General Rules and Market Information will apply.
        </p>
        <p>
          2. Market information
          <br />
          The Market Information is provided for information purposes as an
          at-a-glance guide on how to manage the market. The Market Information
          may also contain rules on market settlement, however it must always be
          read in conjunction with the General Rules and the relevant Specific
          Sports Rules and a link to this Rules and Regulations page will
          usually be provided in the Market Information for each market.
        </p>
        <p>
          Whilst the Market Information may give a guide to how markets will be
          managed, regardless of what it says in the Market Information, it
          reserves the right to suspend any market at any time at its sole
          discretion, including in order to carry out necessary administration
          and/or to take any necessary action to protect the customers.
        </p>
        <p>
          It shall not amend the Market Information after a market has been
          loaded except to correct obvious errors and/or to add wording to
          clarify the Market Information where appropriate.
        </p>
        <p>
          3. Customer responsibility
          <br />
          The customers should make themselves aware of all the Exchange Rules
          affecting any market on which they wish to place a bet. Customers
          should not simply rely on the Market Information as the Market
          Information is unlikely to contain all of the applicable rules
          relating to a market.
        </p>
        <p>
          By their nature &lsquo;Special Bets&rsquo; markets are unpredictable
          so should be treated with particular caution. Customers are
          responsible for managing their own positions at all times in such
          markets. Customers should pay particular attention to the Market
          Information on &lsquo;Special Bets&rsquo; markets to ensure they
          understand the basis on which the market will be administered and
          settled.
        </p>
        <p>
          4. Customer betting disputes &amp; IBAS
          <br />
          Any customer who has any concerns or questions regarding the Exchange
          Rules or regarding the settlement of any market should contact.
        </p>
        <p>
          If a customer is not satisfied with how a bet or a market has been
          settled then the customer should provide details of their grievance.
        </p>
        <p>
          Part B - General rules
          <br />
          1. Managing markets In-Play
          <br />
          a) General
          <br />
          For everything other than horseracing and greyhound racing, if a
          market is not scheduled to be turned in-play but fails to suspend the
          market at the relevant time, then:
          <br />
          if the event has a scheduled 'off' time, all bets matched after that
          scheduled off time will be void; and
          <br />
          if the event does not have a scheduled 'off' time, it will use its
          reasonable endeavours to ascertain the time of the actual 'off' and
          all bets after the time of the 'off' determined will be void.
          <br />
          For horseracing and greyhound racing, if a market is not scheduled to
          be turned in-play but fails to suspend the market at the relevant
          time, then all bets matched after the official 'off' time will be
          void.
          <br />
          It aims to use its reasonable endeavours to suspend in-play markets at
          the start of and at the end of the event. However, regardless of what
          it says in the Market Information, it does not guarantee that such
          markets will be suspended at the relevant time.
          <br />
          It will not part-suspend outcomes/selections in an Exchange market
          that has been turned in-play.
          <br />
          Customers are responsible for managing their in-play bets at all
          times.
          <br />
          For the purposes of in-play betting, customers should be aware that
          transmissions described as "live" by some broadcasters may be delayed
          or pre-recorded. The extent of any delay may vary depending on the
          set-up through which they are receiving pictures or data.
          <br />
          b) All markets other than soccer markets and Australian markets - not
          suspending at the time of the 'off'
          <br />
          In relation to markets which are scheduled to be turned in-play, aims
          to use its reasonable endeavours to turn such markets in-play at the
          time of the 'off'. The time of the 'off' for such markets should be
          set out in the Market Information. However, regardless of what it says
          in the Market Information, it does not guarantee that such markets
          will be suspended and turned in-play at the time of the 'off'.
          <br />
          If a market is scheduled to be turned in-play but does not suspend the
          market and cancel unmatched bets at the time of the 'off' and the
          market is not turned in-play with unmatched bets cancelled at any time
          during the event, all bets matched after the scheduled time of the
          'off' will be void (in the case of horseracing and greyhound racing,
          bets will be void from the official rather than the scheduled 'off'
          time).
          <br />
          If the event does not have a scheduled 'off' time, it will use its
          reasonable endeavours to ascertain the time of the actual 'off' and
          all bets after the time of the 'off' determined will be void.
          <br />
          If a market is scheduled to be turned in-play but does not suspend the
          market at the time of the 'off' (so unmatched bets are not cancelled
          at that time), but the market is intentionally turned in-play at a
          later time during the event, all bets matched after the time of the
          'off' will stand
          <br />
          c) Soccer markets (with the exception of Australian soccer markets) -
          not suspending at kick-off or on the occurrence of a Material Event
          <br />
          Not suspending at kick-off
          <br />
          In relation to soccer markets that are scheduled to be turned in-play,
          aims to use its reasonable endeavours to turn such markets in-play at
          kick-off and to suspend such markets on the occurrence of a Material
          Event (see definition of "Material Event" below).
          <br />
          The relevant scheduled kick-off time should be set out in the Market
          Information. However, regardless of what it says in the Market
          Information, it does not guarantee that such markets will be suspended
          and turned in-play at kick-off.
          <br />
          If a market is scheduled to be turned in-play but not suspend the
          market at kick-off and the market is not turned in-play at any time
          during the match, all bets matched after the scheduled time of the
          kick-off will be void.
          <br />
          If a market is scheduled to be turned in-play but not suspend the
          market at kick-off (so unmatched bets are not cancelled at that time),
          but the market is turned in-play at a later time during the match, all
          bets matched after the scheduled time of the kick-off and before the
          first "Material Event" will stand. However, if there has been one or
          more "Material Events", any bets matched between the first "Material
          Event" and the market being turned in-play will be void.
          <br />
          Not suspending on the occurrence of a Material Event
          <br />
          If it does not suspend a market on time for the occurrence of a
          Material Event, it reserves the right to void bets unfairly matched
          after the Material Event has occurred. Voiding of these bets may take
          place during the event or retrospectively once a game is completed.
          <br />
          Definition of &ldquo;Material Event&rdquo;
          <br />
          For the purpose of these Exchange Rules, a "Material Event" shall mean
          a goal being scored, a penalty being awarded or a player being sent
          off.
          <br />
          d) In-play Australian markets
          <br />
          Notwithstanding the other in-play rules described above, in relation
          specifically to any Australian market that is scheduled to be turned
          in-play, if it fails to suspend the market at the 'off' then all bets
          matched after the scheduled time of the 'off' and before the market is
          turned in-play will be void (in the case of horseracing and greyhound
          racing, bets will be void from the official rather than the scheduled
          &lsquo;off&rsquo; time). If the event does not have a scheduled 'off'
          time, it will use its reasonable endeavours to ascertain the time of
          the actual 'off' and all bets after the time of the 'off' as
          determined will be void.
          <br />
          2. Results and market settlement
          <br />
          a) General
          <br />
          Markets will be settled as set out in the Market Information and/or
          the Specific Sports Rules.
          <br />
          Where the Market Information or Specific Sports Rules do not specify
          how and on what basis a market will be settled, markets will be
          settled on the official result of the relevant governing body
          regardless of any subsequent disqualification or amendment to the
          result (except if an amendment is announced within 24 hours of the
          initial settlement of the relevant market in order to correct an error
          in reporting the result).
          <br />
          If no official result of a relevant governing body is available, the
          result will be determined by using information from independent
          sources. In such cases, if any new information comes into the public
          domain within 48 hours of settlement, then it shall (acting
          reasonably) determine either: (i) whether the market should be
          reinstated or resettled in light of this new information; or (ii)
          whether or not to wait for further information before deciding whether
          to reinstate or resettle the market. Except it has announced that it
          is waiting for further information, any information that comes into
          the public domain more than 48 hours after a market has been settled
          shall not be considered (regardless of whether or not such information
          may have led to a different result).
          <br />
          In the event of any uncertainty about any result or potential result,
          it reserves the right to suspend settlement of any market for an
          unlimited period until the uncertainty can be resolved to the
          reasonable satisfaction. It reserves the right to void any market if
          the uncertainty regarding settlement cannot be resolved to reasonable
          satisfaction.
          <br />
          b) Resettlements
          <br />
          Markets are generally settled shortly after the end of the event in
          question. It may settle (or part-settle) some markets before the
          official result is declared (or may increase a customer's 'available
          to bet' balance by the minimum potential winnings of that customer on
          a given market) purely as a customer service benefit. However, it
          reserves the right to amend the settlement of the market if: (i) the
          official result is different to the result on which initially settled
          the market (for example, a horseracing result being changed by the
          relevant governing body shortly after a race, but before the result is
          official); or (ii) if the whole market is eventually voided (e.g. for
          an abandoned event).
          <br />
          It reserves the right to reverse the settlement of a market if a
          market is settled in error (for example, a human or technical error).
          <br />
          If resettles a market, this may lead to amendments being made to a
          customer's balance to reflect changes in market settlement.
          <br />
          c) Non-runners, withdrawals and disqualifications
          <br />
          Subject always right to void bets under its terms and conditions or
          for any exception under the Exchange Rules, if a market contains a
          statement that says, "All bets stand, run or not" (or something
          similar) in the Market Information, then all bets on a team or
          competitor will stand regardless of whether or not the team or
          competitor starts the event or takes any part in the event.
          <br />
          Where the Market Information does not stipulate that all bets will
          stand regardless of participation, customers should refer to the
          relevant Specific Sports Rules.
          <br />
          If a team or competitor is disqualified, withdraws or forfeits after
          starting an event they will be deemed a loser providing at least one
          other team or competitor completes the event. If no team or competitor
          completes an event (having started) then all bets will be void except
          for bets on any markets which have been unconditionally determined.
          <br />
          d) Winner with [named selection]' markets
          <br />
          It may from time to time offer markets that are dependent on the
          participation of a particular competitor. If the competitor named
          either in a 'Winner with &hellip;' market title or in the Market
          Information does not participate in the tournament or event then all
          bets on the market will be void. For example, if there was a "Winner
          with Federer" tennis market, all bets on the market would be void if
          Federer did not participate in the tournament. However, if any other
          competitor did not participate, then bets would stand.
          <br />A team or competitor will be deemed to have participated if they
          have taken part to the extent necessary to record an official result
          or classification (including any disqualification but excluding any
          "did not start" or equivalent classification).
          <br />
          3. Abandonments, Cancellations, Postponements
          <br />
          Some markets have different rules and these are listed in the Specific
          Sports Rules and/or the Market Information. However, where a market
          has no rules in the Specific Sports Rules or the Market Information in
          relation to an abandonment, cancellation and/or postponement the
          following shall apply.
          <br />
          In relation to any match, fixture, game, individual event, race or
          similar: If the event is not completed within three days after the
          scheduled completion date, then all bets on markets for this event
          will be void, except for bets on any markets that have been
          unconditionally determined.
          <br />
          In relation to any tournament, competition or similar: If the event is
          not completed within three days after the scheduled completion date,
          then any markets relating to the event will be settled in accordance
          with the official ruling of the relevant governing body, providing
          such a decision is given within 90 days after the scheduled completion
          date. If no official ruling is announced in this 90-day period, then
          bets on any market relating to this event will be void, except for
          bets on any markets which have been unconditionally determined. If a
          market is to be voided but has been part-settled as a courtesy to
          customers, then such part-settled bets will be reversed and all bets
          on the market will be void.
          <br />
          It will decide (acting reasonably) whether a market relates to a match
          (or similar) or a tournament (or similar). However, by way of example,
          the following shall apply: (i) Europa League outright = tournament;
          (ii) Champions&rsquo; League Group outright = tournament; (iii) Top
          Premiership goal scorer = tournament; (iv) 72-hole Golf Match bet =
          match; (v) Ryder Cup outright = tournament; (vi) Golf tournament
          outright = tournament; (vii) Tennis Tournament outright =
          tournament;(viii) 5-day Cricket Test Match = match; (ix) Ashes Series
          outright winner = tournament; (x) Motor Race (e.g. Grand Prix) =
          match.
          <br />
          4. Change of venue
          <br />
          Some markets have different rules and these are listed in the Specific
          Sports Rules and/or the Market Information. However, if change of
          venue is not dealt with in the Specific Sports Rules and/or the Market
          Information then the following shall apply:
          <br />
          For any team sport: if the scheduled venue is changed after the market
          is loaded, all bets will be void only if the new venue is a home
          ground of the original away team
          <br />
          For all categories or markets other than team sports: if the scheduled
          venue is changed after the market is loaded, all bets will stand.
          <br />
          If there is a change in the type of scheduled surface (e.g. a hockey
          match switching from grass to Astor-turf) after the market has been
          loaded, all bets will stand.
          <br />
          5. Periods of time
          <br />
          Some markets have different rules and these are listed in the Specific
          Sports Rules and/or the Market Information. However, if not dealt with
          in the Specific Sports Rules or the Market Information then the
          following shall apply.
          <br />
          If the scheduled duration of an event is changed after the market has
          been loaded but before the start of the event, then all bets will be
          void.
          <br />
          Some markets refer to the length of time until an occurrence in the
          event (e.g. time of first goal). If an event happens in stoppage or
          injury time after any regular time period then it will be deemed to
          have occurred at the end of the regular time period. For example, if a
          goal is scored in first half stoppage-time in a soccer match it will
          be deemed to have occurred on 45 minutes.
          <br />
          All bets apply to the relevant full 'regular time' period including
          stoppage time. Any extra-time and/or penalty shoot-out is not
          included.
          <br />
          References within these Rules and Regulations to a particular number
          of 'days' shall mean the end of the day local time after the expiry of
          the specified number of days. For example, if a rugby match is
          scheduled for the 1st of December, then the rule that allows the match
          to be completed within three days after the scheduled completion date
          (see Paragraph 3 above) would mean that the deadline for completion of
          that match would be 23.59:59 on the 4th of December.
          <br />
          6. Match bets
          <br />
          Some markets have different rules and these are listed in the Specific
          Sports Rules and/or the Market Information. For example, for match
          bets on Golf markets the rules are set out in the Specific Sports
          Rules. However, if not dealt with in the Specific Sports Rules or the
          Market Information then the following shall apply.
          <br />
          'Match Bets' for one-off events are determined by the competitor or
          team with the best score, time or finishing position in the event. If
          none of the competitors or teams involved in the match bet complete
          the event or register a score, time or finishing position then bets
          are void unless specified otherwise in the Specific Sports Rules
          and/or the Market Information. Any competitor or team not completing
          an event or registering a score, time or finishing position having
          taken part in the match bet event will be settled as a loser providing
          at least one other team or competitor completes that event or
          registers a score, time or finishing position.
          <br />
          'Match Bets' for progress in a competition or event with multiple
          heats or rounds are determined by the competitor or team which
          qualifies to the furthest round (whether it takes part in the further
          round or not) or with the best score, time or finishing position in
          the final or same heat of that competition or event. If the relevant
          competitors or teams fail to qualify in the same round of the
          competition but in different heats then dead-heat rules will apply,
          irrespective of the finishing positions in their respective heats.
          Markets will be part-settled after the end of each round and any
          subsequent disqualifications, penalties or amendments to results or
          qualifications will not have any effect on the market. Where one or
          more of the competitors or teams are disqualified, for the purposes of
          settlement, the disqualified competitor or team will be deemed to have
          progressed further in the competition or event than all those
          eliminated from the competition or event prior to the
          disqualification, and will be deemed to have finished last (or joint
          last if there is more than one disqualification) of those still
          competing in the competition or event. Disqualification will be
          considered to have taken place at the time of the competitor's or
          team's removal from the competition or event by the relevant governing
          body, rather than at the time of the event which caused
          disqualification.
          <br />
          If one of the competitors or teams does not take any part in the
          event, then all relevant match bets will be void.
          <br />
          If an event or tournament is abandoned or reduced in duration such
          that any competitor or team fails to complete the match bet, event or
          tournament for any reason other than withdrawal or disqualification
          then all bets will be void except for those on markets which have been
          unconditionally determined.
          <br />
          7. "To qualify" markets
          <br />
          Some markets have different rules and these are listed in the Specific
          Sports Rules and/or the Market Information. However, if not dealt with
          in the Specific Sports Rules or the Market Information then the
          following shall apply.
          <br />
          Any 'to qualify' market (e.g. "to reach the final" markets) will be
          determined by the competitor or team that qualifies under the terms
          set out in Market Information, whether or not they take part in the
          next round or event for which they have qualified. Markets will be
          settled after the qualifying stage and any subsequent disqualification
          or amendment to the result will not count.
          <br />
          8. Dead heats
          <br />
          Unless stated otherwise in the Specific Sports Rules and/or the Market
          Information the Dead Heat Rule applies to bets on a market where there
          are more winners than expected (as set out in the Market Information).
          <br />
          For each bet matched on a relevant winning selection, the stake money
          is first reduced in proportion by multiplying it by the sum of the
          number of winners expected (as set out in the Market Information),
          divided by the number of actual winners (i.e. stake multiplied by
          (number of winners expected/number of actual winners)). The winnings
          are then paid to the successful backers on this 'reduced stake'
          (reduced stake multiplied by traded price) and the remaining stake
          money is paid to the appropriate layers.
          <br />
          For example, assume there is a dead heat for first place between three
          horses. 'Client A' has backed one of the winners for a stake of 300 at
          the traded price of 4.0 and 'Client B' has taken the other side of
          this bet. When the event is settled, the stake (300) is multiplied by
          1/3 (i.e. the number of expected winners (1) divided by the number of
          actual winners (3)) to calculate the reduced stake (100) and the
          remainder given to the layer (200). The backer then receives the
          traded price matched (4.0) multiplied by the reduced stake (4 x 100 =
          400). In this example, Client A's net winnings are 100 (400 payout
          minus the original 300 stake), and Client B's net losses are 100.
          Alternatively, this can be viewed as the full amount paid to the
          traded price divided by the number of dead-heaters. In the above
          example, this would be 300 at the traded price of 4.0 (1200) divided
          by 3; making 400 payouts and 100 net winnings.
          <br />
          By way of another example, assume there is an outright winner in a
          golf tournament but 7 players tie for 2nd place. In the "top 5 finish"
          market, after settling on the outright winner in the top 5 market
          there would be 4 others designated winner&rsquo;s places available.
          'Client A' has backed one of the winners for 300 at the traded price
          of 4.0 and 'Client B' has taken the other side of this bet. When the
          event is settled, the stake (300) is multiplied by 4/7 (i.e. the
          number of expected winners (4) divided by the number of actual winners
          (7)) to calculate the reduced stake (171.43) and the remainder given
          to the layer (128.57). The backer then receives the traded price
          matched (4.0) multiplied by the reduced stake (4 x 171.43 = 685.72).
          In this example, Client A's net winnings are 385.72 (685.72 payout
          minus the original 300 stake), and Client B's net losses are 385.72.
          <br />
          In relation to customers whose betting counterparty, for the purposes
          of this section of the Exchange Rules dealing with dead heats, the
          terms 'Client A', 'Client B' and 'appropriate layers' should, where
          relevant, be read
          <br />
          9. Exchange Multiples
          <br />
          The Exchange Rules only apply to Exchange Multiples.
        </p>
        <p>
          Customers placing a multiple bet will be betting with each other will
          act as the counterparty to the bet.
          <br />A multiple bet consists of a number of legs. A leg is defined as
          one or more chosen selections in any individual event market.
          <br />
          It reserves the right in its sole discretion not to accept certain
          multiple bets or to scale back stakes in certain circumstances.
          <br />
          All multiple bets placed are subject to the Exchange Rules that apply
          to each individual sport that relates to any leg of any multiple bet.
          <br />
          The maximum payout limit for Exchange Multiples is &pound;1,000,000.
          <br />
          Customers can place an Exchange Multiple bet using back, or where
          available lay or a mixture of back and lay selections. However,
          customers cannot have a combination of back and lay selections in any
          one leg.
          <br />
          If customers choose more than one selection in one leg the odds for
          this leg will be "dutched" which means they will be combined to
          reflect the chances of any of the selections within the leg winning
          (if backed) or all of the selections losing (if laid). If customers
          wish to place a cross-multiple bet (i.e. more than one selection in
          any event market but not using "dutched" prices) they will need to
          enter each Exchange Multiple bet separately.
          <br />
          Other than Exchange Multiples bets struck at Starting Price ("BSP"),
          the odds available via the Exchange Multiples product will be broadly
          based on the relevant singles markets on the Exchange and any such
          winning multiple bet will be subject to commission as set out in the
          Charges' section of the website.
          <br />
          Any winnings from Exchange Multiples bets struck at BSP will not be
          subject to commission, but the odds returned on each leg in such a
          multiple will be subject to a 5% deduction from the BSP.
          <br />A BSP each way Exchange Multiple bet is a bet for selections in
          the multiple to win and a bet on the same selections to place. For
          example, a &pound;2 each way double represents a &pound;2 bet on both
          selections to win and a &pound;2 bet on both selections to be placed,
          with a total stake for the bet of &pound;4. The number of places for
          each event in each way multiples bet are shown on the multiples win
          Market Information and do not change. Should the number of runners be
          equal to or less than the number of places available, the relevant
          place leg of any multiples bet will be void
          <br />
          The minimum total stake for any Exchange Multiple bet is &pound;2. For
          example, a 20p "Yankee" (11 bet combinations) representing a total
          stake of &pound;2.20 is permitted. However, a 1p "Heinz" (57 bet
          combinations) representing a total stake of &pound;0.57 or a &pound;1
          double (1 bet combination) representing a total stake of &pound;1 will
          not be permitted. For customers betting in currencies other than
          English Sterling, the minimum total stake which applies to an Exchange
          Multiple bet will not necessarily be the equivalent of &pound;2,
          though it will be calculated as described in the example above.
          <br />
          If, in its sole discretion, will determine the markets that are
          available for Exchange Multiples. Events available for Exchange
          Multiples will be those listed within any individual Exchange Multiple
          group (e.g. UK football fixtures for any given day). Not all markets
          will be available via the Exchange Multiples product.
          <br />
          With the exception of the rule directly below, if any selection in any
          leg is a non-runner or otherwise void under the Exchange Rules (e.g.
          an abandoned match) then all bets on that individual leg will be void
          and the Exchange Multiple bet shall be adjusted accordingly. For
          example, a treble including one void leg will become a double. This
          means that if customers have more than one selection in any leg (i.e.
          in cases of 'ditching') and one of those selections is a non-runner,
          the whole leg will be void. In the event that voided legs mean that an
          individual bet within a multiple becomes a single bet, then this
          single bet will stand.
          <br />
          Notwithstanding the rule directly above, for Exchange Multiples bets
          struck at BSP, if customers have more than one selection in any leg
          and one of those selections is a non-runner the leg will stand. For
          example, if customers back both Desert Orchid and Red Rum in an
          individual horse race and Red Rum is a non-runner, the leg will become
          a back of just Desert Orchid.
          <br />
          It reserves the right not to accept certain combinations of Exchange
          Multiples, such as those including related contingencies (i.e. where
          the outcome of one event is likely to affect the odds on the outcome
          of another event). This may happen automatically at the bet placement
          stage. Alternatively, if such bet is taken in error, it may void the
          individual bet combinations which include two or more of the related
          contingency selections.
          <br />
          It may offer special markets on related events from time to time,
          including within its normal markets (i.e. outside of the Exchange
          Multiples product), for example a market on Chelsea to win the English
          domestic double (i.e. Premiership and FA Cup).
          <br />
          When placing any Exchange Multiple bet, the prices shown only give an
          indicative guide as to the price available for each leg and the
          overall multiple. Other than Exchange Multiples bets struck at BSP,
          the price of each leg and the overall multiple price that customers
          get will be fixed when the multiple bet is actually placed and
          customers will then be able to see these prices. For more details on
          this customer should refer to the 'help' files.
          <br />
          Horseracing Exchange Multiples are based on &lsquo;day of the
          race&rsquo; markets (and not ante-post markets). Horseracing ante-post
          rules do not therefore apply in relation to horseracing Exchange
          Multiples.
          <br />
          10. Rules for Starting Price
          <br />
          Starting Price ('SP') is available on the Exchange. Exchange SP bets
          (&lsquo;SP bets&rsquo;) are therefore a type of Exchange bet. The SP
          is calculated as, by balancing all SP bets and other Exchange bets
          when the market is suspended at the 'off' of the relevant event. The
          details of this calculation are provided directly below.
          <br />
          Please note that for all customers, an SP bet cannot be cancelled once
          it has been placed.
          <br />A bet at SP is a fixed odd bet, with the odds on each selection
          being calculated and bets matched when the event starts. The odds are
          calculated by matching SP backers and other Exchange backers against
          SP layers and other Exchange layers. The inclusion of other Exchange
          bets in the SP reconciliation is necessary to ensure that: SP backers
          get the benefit of unmatched Exchange offers to lay if those offers
          could increase the SP; and SP layers get the benefit of unmatched
          Exchange offers to back if those offers could reduce the SP. Including
          unmatched other Exchange in the SP reconciliation also ensures that
          the bets of other Exchange backers and layers, which would otherwise
          lapse, are matched where possible.
          <br />
          The minimum liability for a bet placed at SP is &pound;10 for a lay
          bet and &pound;2 for a back bet (or the currency equivalent in each
          case).
          <br />
          Example 1: on selection A there is &pound;1,000 of backers' stakes and
          &pound;6,000 of layers' liabilities at SP and there are &pound;500 of
          unmatched other Exchange back bets available to lay at an average of
          5.0. If we ignored the Exchange market in this case the SP would be
          7.0. However, this would leave other Exchange back bets, that could
          also have been matched at their requested price against SP layers,
          unmatched. Therefore, the SP will be 5.0 and all SP backers and SP
          layers will be matched at that price. The &pound;500 of Exchange
          backers' stakes will also be matched at their requested price of 5.0
          against the SP layers.
          <br />
          Example 2: on selection B there are &pound;831 of backers' stakes and
          &pound;4,428 of layers' liabilities at SP and the following unmatched
          other Exchange lay bets, available to back: &pound;20 at 6.8,
          &pound;31.13 at 6.6 and &pound;100 at 6.4. In this case the SP will be
          6.68. This is calculated by including the &pound;20 available to back
          at 6.8 and the &pound;31.13 available to back at 6.6 and balancing
          those amounts against the SP backers' stakes and SP layers'
          liabilities. The &pound;100 available to back on the Exchange at 6.4
          remains unmatched as to include any of this amount would lead to an
          imbalance between SP backers and SP layers.
          <br />
          The SP is calculated to six decimal places for each selection, though
          it may be displayed in the relevant market view (or in any
          form/results data) to two decimal places, rounded up or down as
          applicable. After reconciliation, the full SP on each selection is
          available by clicking on the relevant individual runner graph.
          <br />
          If for whatever reason the site is unavailable when an event starts or
          the SP cannot otherwise be reconciled at the 'off&rsquo;, it will
          determine the SP using all available information. For the avoidance of
          doubt this information will not be limited to betting activity on the
          relevant market(s). Also in these circumstances, a licensed betting
          operator within the group may act as risk counterparty to SP bets if
          necessary to ensure a fair SP. The personnel involved in determining
          the SP in such circumstances will have no undeclared personal or other
          interest in the SP in question.
          <br />
          If the SP reconciliation process is undertaken prematurely (for
          example if a horserace is turned in-play in error), then it will
          endeavor to reverse the reconciliation so that the SP is determined
          when the event starts. There may however be circumstances in which
          this is not possible, in which case the SP will be based on the
          initial reconciliation.
          <br />
          In cases where an SP reconciliation is reversed: SP bets (including SP
          limit bets), 'At In-play: Take SP' bets and 'At In-play: Keep' bets
          will all revert to their status before the reconciliation; and 'At
          In-play: Cancel' bets will either remain cancelled if not matched as
          part of the reconciliation process, or if matched as part of the
          reconciliation process, will revert to their unmatched status before
          the reconciliation.
          <br />
          If the SP reconciliation process is undertaken later than scheduled
          (i.e. after the event has started) and determines that a material
          event has occurred (this will generally mean that the event is not
          turned in-play), the SP will be determined based solely on SP bets
          (and &lsquo;At In-play: Take SP&rsquo; bets which are unmatched when
          suspends the relevant market), placed before the 'off'. This means
          that &lsquo;At In-play: Take SP&rsquo; bets matched after the off,
          will be made void and not included in the reconciliation process. In
          addition, SP bets placed after the 'off' will be voided. However, if
          the SP reconciliation process is undertaken later than scheduled but
          determines that no material event has occurred, all bets will stand.
          <br />
          If a SP 'each way' option is offered this will be processed as two
          separate bets: a win bet at SP and a 'to be placed' bet at SP.
          <br />
          If for any reason unmatched bets are cancelled prior to a market going
          in-play, then any unmatched bets that have been selected to 'At
          In-play: Take SP' will be converted to SP bets. Once those bets are
          converted, they cannot be cancelled.
          <br />➢ Party against whom you are betting at SP
          <br />
          When you place a bet at SP you are betting against other customers.
          However, during the reconciliation of the SP, it acts as counterparty
          in order to balance liabilities between SP bets and other Exchange
          bets
          <br />➢ Placing a SP bet
          <br />
          Selecting the 'SP' button in the market view gives customers two
          different ways to request an SP bet. These are as follows:
          <br />
          The first way to request a bet at SP is by leaving the 'Set SP odds
          limit' box unticked at the top right of the bet manager. For a back
          bet you are required to enter the stake you wish to bet on the
          selection. For a lay bet, you are required to enter the liability you
          are prepared to risk against the selection &ndash; in other words the
          amount that you are prepared to lose, should the selection win (or be
          placed as applicable).
          <br />
          The second way to request a bet at SP is to select the 'Set SP odds
          limit' option. Using this you can request a bet at SP conditional upon
          minimum SP odds in the case of a back bet, or maximum SP odds in the
          case of a lay bet. If SP is shorter than the minimum price requested
          by a backer or is longer than the maximum price requested by a layer,
          then the relevant bet will lapse when the event starts. If SP is
          longer than the minimum price requested by a backer or is shorter than
          the maximum price requested by a layer, the bet will be matched at the
          SP. Where an SP is equal to the price limit specified by customers,
          those bets will be included based on the time they were submitted, on
          a first come, first served basis as is the existing convention for
          other Exchange bets. This means that such bets may be unmatched or
          partially matched.
          <br />
          Please note that if a non-runner cannot be removed from the relevant
          market until after the completion of the event, the application to all
          bets matched at or before the "off" (including SP bets) of any
          reduction factor, may mean that the matched price for an SP back bet
          with an odds limit requested, is adjusted to a price below the lower
          limit requested. It may also mean that an SP lay bet request with
          maximum odds requested, may not be matched even though the odds on the
          selection, after any post-race adjustment for the late non-runner, is
          below the maximum odds requested.
          <br />
          If an SP bet with an odds limit is requested, the minimum/maximum odds
          requested can be shortened in the case of a back bet or a lengthened
          in the case of a lay bet at any time before the event starts. However,
          as mentioned above, an SP bet request cannot actually be cancelled by
          a customer once it has been placed. Details of the impact of
          non-runners on the SP re provided below.
          <br />➢ Exchange bets which are unmatched at the 'off'
          <br />A normal Exchange bet is placed by choosing the odds of your
          selection from the market view (as opposed to clicking on the 'SP' of
          your selection). When such an Exchange bet is fully or partially
          unmatched it can be adjusted and cancelled in the normal course.
          Previously, unmatched bets were automatically cancelled when a market
          was suspended at the 'off' of the relevant event. You can now choose
          to either have your unmatched Exchange bet convert to an SP bet when
          the market suspends at the start of the event, or to have the bet
          'persist' when the event goes in-play (see the 'keep' option below).
          <br />
          In order to convert your unmatched Exchange bet to an SP bet when the
          market suspends you should select the 'At In-Play: Take SP' option in
          the bet manager. If there is a non-runner in a win market with a
          reduction factor of at least 2.5%, or a non-runner in a place market
          with a reduction factor of at least 4%, it is policy to cancel
          unmatched lay bets on all other runners in the market. In the case of
          any such non-runner, instead of being cancelled a lay bet for which
          the 'At In-Play: Take SP' option has been selected, will automatically
          convert to an SP bet. After this conversion, the bet cannot be
          cancelled. Otherwise, unlike an SP bet, you can choose to cancel an
          Exchange bet once it has been placed, even if you have chosen for the
          bet to persist or to convert to an SP bet at the start of the event.
          <br />
          If you choose to convert an unmatched Exchange lay bet to an SP bet,
          the liability of your Exchange lay bet will be converted into a
          liability for the SP bet. Your liability for that SP bet will never be
          more than the liability for the Exchange bet you had specified.
          However, the amount you can win on the SP lay bet may differ from the
          amount you would have won had the Exchange bet been matched, depending
          on the final SP.
          <br />➢ Adjustments to SP bets for non-runners
          <br />
          For SP back bets, will at no point amend either the stake or the odds
          requested by a customer, despite any non-runners or withdrawals.
          However, customers who have selected the SP limit option can reduce
          the minimum SP odds they are prepared to accept on a selection.
          <br />
          For SP lay bets on win markets, it will reduce a customer's liability
          based on the reduction factor(s) of any non-runner(s) and the
          reduction factor of the runner on which the customer's bet has been
          placed. This is to ensure that the balance between the backers' stakes
          and the layers' liability reflects the revised market after the runner
          has been removed. For example, if a horse with a 50% reduction factor
          becomes a non-runner, then another horse in the same market priced at
          about 5 (i.e. a reduction factor of 20%) will change to a price of
          about 2.5. Therefore, the liability on a &pound;200 lay bet on that
          runner will need to change to a liability of about &pound;75 to ensure
          that a balancing back bet will have the same &pound;50 stake. This is
          done by multiplying the liability by 37.5% (i.e. 100%-(50%/
          (100%-20%))).
          <br />
          Where an SP lay bet in a win market has a maximum odds limit
          specified, this limit will be reduced by the reduction factor of any
          non-runner, if the non-runner has a reduction factor of at least 2.5%.
          <br />
          For SP lay bets on place markets, it will still reduce a customer's
          liability based on the reduction factor(s) of any non-runner(s) but
          the calculation will be slightly different, in line with the
          application of place market reduction factors. The liability will be
          reduced by the reduction factor of the removed runner. Where the lay
          bet on a place market has a maximum odds limit specified, the
          potential winnings on the bet (i.e. the odds &ndash; 1) will be
          reduced by the reduction factor of the removed runner.
          <br />
          Where an SP lay bet in a place market has a maximum odds limit
          specified this limit will be reduced by the reduction factor of any
          non-runner.
          <br />
          SP lay bets will not be cancelled when there is a non-runner.
          <br />➢ Specific conditions for SP bets for non-runners in greyhound
          racing markets
          <br />
          ALL SP bets will stand so long as your trap selection is not vacant or
          after your bet placement a reserve runner is not subsequently entered
          to run from your trap selection.
          <br />
          ALL unmatched &lsquo;Convert to SP&rsquo; bets will be cancelled when
          a reserve is added or a vacant trap removed.
          <br />
          11. 'Keep' bets option
          <br />
          For markets that are scheduled to be turned in-play at the 'off', a
          customer can request that an unmatched Exchange bet should not be
          cancelled when the market is turned in-play. This is done by selecting
          the 'At In-Play: Keep' option in the bet manager (and confirming that
          request) and means that the unmatched bet persists when other
          unmatched bets are cancelled at the start of the event.
          <br />
          As described above, when a non-runner is removed from a horseracing
          market (apart for late withdrawals as described in the next bullet
          point below), it is policy to cancel unmatched offers to lay all other
          horses in the market if the non-runner has a reduction factor of 2.5%
          or greater for win markets, or 4.0% or greater for place markets. In
          these circumstances offers to lay a horse with the 'At In-Play: Keep'
          option selected will not be cancelled. Instead the lay odds offered in
          place markets will be reduced in proportion with the reduction factors
          of any non-runner(s) and the same will apply in win markets providing
          the relevant non-runner has a reduction factor of at least 2.5%.
          <br />
          When there is a late withdrawal, may not have time to remove the
          non-runner from the market before turning it in-play. In such cases if
          can determine that the late withdrawal is a material runner (i.e. a
          selection with a reduction factor of approx. 20% or greater in the win
          market), it reserves the right to cancel ALL lay 'keep' bets (in both
          the win and &lsquo;to be placed&rsquo; markets) before turning the
          market in-play. If it does not cancel lay 'keep' bets in the case of a
          late withdrawal (for example, it may not be possible to know which
          horse is withdrawn, at the time of the off), any such bets placed
          before the off and matched in-play will remain at the original
          selected price. This means that those lay 'keep' bets will not be
          subject to any reduction factor which as a result of the late
          withdrawal, will be applied after the completion of the race to bets
          matched at or before the 'off'.
          <br />
          Otherwise, in exceptional cases reserves the right to cancel 'keep'
          bets to protect customers but, unless stipulated in the market rules
          or the market information, the general principle is that at no point
          will a 'keep' bet be cancelled by unless it remains unmatched when the
          market is closed (for the final time) at the end of the event.
          <br />
          For the avoidance of doubt this means that (for example) when a
          Material Event occurs in a soccer match and other unmatched bets are
          cancelled before the market is reopened, a keep bet will not be
          cancelled.
          <br />
          12. Rules for Tote betting
          <br />
          When you place a Tote bet on the platform (for example a UK or South
          African Tote bet), you are betting against as counterparty to your
          bet. I will then strike a corresponding bet into the relevant Tote
          pool as applicable.
          <br />A link to the rules which apply to Tote bets struck as
          counterparty is provided within each of the relevant. If there is any
          conflict between those rules and either: the equivalent rules
          available on the relevant Tote website (for example on the UK Tote
          website in the case of UK Tote bets); or the equivalent rules applied
          by the relevant host racetrack, the rules on the relevant Tote website
          or applied by the relevant host racetrack, will prevail.
          <br />
          13. Miscellaneous
          <br />
          All references to time periods in the Exchange Rules relate to the
          time zone in which the event takes place. For example, a reference to
          the start time of a football match, relates to the local kick-off
          time.
          <br />
          All information is done so in good faith. However, it cannot accept
          liability for any errors or omissions in respect of any information,
          such as the posting of prices, runners, times, scores, results or
          general statistics.
          <br />
          It reserves the right to correct any obvious errors and shall take all
          reasonable steps to ensure markets are administered with integrity and
          transparency.
          <br />
          If an incorrect team or competitor name is displayed (excluding minor
          spelling mistakes) or the incorrect number of teams, competitors or
          outcomes is displayed in any complete market or a market is otherwise
          loaded using incorrect information (for example the application of an
          incorrect exposure algorithm or a cross matching tool utilising an
          incorrect algorithm) or includes any obvious error such as the
          incorrect deployment of the cross matching tool, then It reserves the
          right to suspend the market and (providing it acts reasonably) to void
          all bets matched on the market.
          <br />
          Customers are responsible for ensuring that they satisfy themselves
          that the selection on which they place a bet is their intended
          selection. For example, in the case of a competitor bearing the same
          name as another individual not competing in the relevant event, the
          onus is on the customer to ensure that they know which competitor It
          has loaded into the relevant market and to ensure that they are
          placing their bet on their chosen competitor.
          <br />
          It reserves the right at any time in its sole and absolute discretion
          to suspend an Exchange market.
          <br />
          It may, in its sole and absolute discretion, decide to suspend betting
          on a market at any time (even if such suspension is earlier than
          anticipated by the Exchange Rules). In the interests of maintaining
          integrity and fairness in the markets, it may also void certain bets
          in a market or void a whole market in its entirety.
          <br />
          It reserves the right to amend the Exchange Rules at any time.
          <br />
          It reserves the right to cancel unmatched bets to protect customers at
          any time.
          <br />
          On the settlement of any market, amounts relating to
          <br />
          winnings/losses on bets; and
          <br />
          commission charges
          <br />
          will be rounded up or down to the nearest two decimal places (with the
          exception that rounding will always be down (and never up) in respect
          of amounts relating to winnings/losses in respect of BSP bets). By way
          of example, &pound;3.333, will be settled as &pound;3.33, whereas
          &pound;3.335, will be settled as &pound;3.34 (except that it will be
          settled as &pound;3.33 in respect of an amount relating to
          winnings/losses in respect of BSP bets).
          <br />
          The Exchange Rules have been prepared in various languages other than
          English for reference only. In the event of any differences between
          the English version and the non-English version, the English version
          shall prevail.
          <br />
          Part C - Specific sports rules
          <br />
          1. American Football
          <br />
          All markets will be settled on the result including overtime unless
          stated otherwise in these American Football Rules or in the Market
          Information (e.g. the half time/full time market) or where the market
          has been unconditionally determined. If the game results in a tie
          after overtime all bets on the outright match winner market will be
          void.
          <br />
          If a match does not start on the scheduled starting date and is not
          completed within three days of the scheduled completion date, all bets
          will be void except for those on markets which have been
          unconditionally determined.
          <br />
          If a match starts but is later abandoned or postponed, then within
          three days of the scheduled start date:(a) at least 55 minutes of play
          must have elapsed; or (b) an official result must be 'called' by the
          relevant governing body; otherwise all bets will be void, except for
          those on markets which have been unconditionally determined. In those
          instances, if the scores are tied then all bets on the outright match
          winner market will be void.
          <br />
          For 'Individual player' markets, bets shall be void on any player who
          does not start in any down during the match.
          <br />
          2. Athletics
          <br />
          Where there is a presentation ceremony, markets will be settled on the
          official result of the relevant governing body at the time of the
          ceremony, regardless of any subsequent disqualification or amendment
          to the result.
          <br />
          If there is no presentation ceremony, outcomes will be determined in
          accordance with the official result of the relevant governing body,
          regardless of any subsequent disqualification or amendment to the
          result (except if an amendment is announced within 24 hours of the
          initial settlement of the relevant market in order to correct an error
          in reporting the result).
          <br />
          Unless stated otherwise in the Market Information, if a track or field
          event is abandoned, cancelled or postponed and not completed within 7
          days of the scheduled completion date, all bets will be void except
          for those on markets which have been unconditionally determined.
          <br />
          3. Australian Rules Football
          <br />
          Bets apply to regular time excluding overtime. However, bets on
          "Finals" matches apply to regular time including overtime except for
          those on markets which have been unconditionally determined or as
          stated otherwise in the Market Information (e.g. half-time/full-time
          markets). If the game results in a tie, dead-heat rules apply to bets
          on the match odds market.
          <br />
          If a match does not start on the scheduled starting date and is not
          completed within three days of the scheduled start date, all bets will
          be void except for those on markets which have been unconditionally
          determined.
          <br />
          If a match starts but is abandoned or postponed before half-time and
          not completed within three days of the scheduled start date then the
          match shall be deemed to be a tie and settled on dead-heat rules. If a
          match starts but is abandoned or postponed in the second half and not
          completed within three days of the scheduled start date then the match
          shall be settled on the official result of the relevant governing
          body. In both instances, bets on the match odds market will be settled
          as set out above and all other bets will be void except for those on
          markets which have been unconditionally determined.
          <br />
          All bets relating to any individual player will stand if they are
          listed in any 22-player squad on the match day, irrespective of
          whether they take any part in the match or not.
          <br />
          4. Baseball
          <br />
          These Baseball Rules apply to Major League Baseball (MLB), World
          Baseball Classic and Japanese Baseball only. All other baseball,
          including Finnish Baseball for example, will be governed by the rules
          set out in the General Rules and the Market Information.
          <br />
          If a match does not start on the scheduled starting date, all bets
          will be void.
          <br />
          If an MLB match starts but is later abandoned or postponed and an
          official result is not declared within three days of the scheduled
          start date, all bets will be void, except for those on markets which
          have been unconditionally determined.
          <br />
          If a Japanese Baseball match starts but is later abandoned or
          postponed and the match is not completed within three days of the
          scheduled start date, all bets will be void except for those on
          markets which have been unconditionally determined.
          <br />
          All bets will include extra innings, unless otherwise stated in the
          Market Information.
          <br />
          All bets on 'Total runs' or 'Run line' markets will be void unless
          there have been at least 9 innings completed (8 1/2 if the home team
          is ahead) except those on market which have been unconditionally
          determined.
          <br />
          In "Listed" markets, bets will be void unless both named pitchers
          listed in the market start as pitchers in the first fielding innings
          for their respective teams.
          <br />
          5. Basketball
          <br />
          Bets will be settled on the result including overtime unless stated
          otherwise in the Market Information or where the market has been
          unconditionally determined or as set out in these Basketball rules.
          <br />
          If a match does not start on the scheduled start date then all bets
          will be void.
          <br />
          If a match starts but is later abandoned or postponed then, within
          three days of the scheduled start date, (a) at least 43 minutes of
          play must have elapsed in any NBA match or 35 minutes of play must
          have elapsed in any other match; or (b) an official result must be
          'called' by the relevant governing body; otherwise all bets will be
          void, except for those on markets which have been unconditionally
          determined. In these instances, if the scores are tied then dead heat
          rules will apply to bets on the outright match winner market.
          <br />
          6. Boxing and Mixed Martial Arts
          <br />
          All individual bout markets will be settled according to the official
          result of the relevant governing body immediately after the end of the
          fight, regardless of any subsequent disqualification or amendment to
          the result (except if an amendment is announced within 24 hours of the
          initial settlement of the relevant market in order to correct an error
          in tallying the points or in order to correct an error in reporting
          the result).
          <br />
          If a fight is abandoned, cancelled or postponed and not completed
          within three days of the scheduled start date, or a boxer is withdrawn
          or substituted before the first-round bell, all bets will be void.
          <br />
          If a boxer retires before the start of a round, fails to answer the
          bell, or is disqualified for any reason between rounds, the fight will
          be deemed to have finished at the end of the previous round.
          <br />
          If one of the boxers is disqualified they will be deemed a loser.
          <br />
          If the scheduled duration of the fight is altered in time or number of
          rounds after the loading of the relevant markets, then all bets will
          be void.
          <br />
          If the scheduled venue is changed after the market has been loaded so
          that the new venue is changed to a venue in a different country then
          all bets will be void. For all other changes to the scheduled venue
          bets will stand.
          <br />
          In mixed martial arts, if a fight is declared a 'no contest', a draw
          or a technical draw then all bets will be void.
          <br />
          7. Cricket
          <br />➢ General
          <br />
          If a ball is not bowled during a competition, series or match then all
          bets will be void except for those on any market that has been
          unconditionally determined (e.g. in the 'Completed Match' market).
          <br />
          If a match is shortened by weather, all bets will be settled according
          to the official result (including for limited overs matches, the
          result determined by the Duckworth Lewis method).
          <br />
          In the event of a match being decided by a bowl-off or toss of the
          coin, all bets will be void except for those on markets that have been
          unconditionally determined.
          <br />➢ Test matches
          <br />
          If a match starts but is later abandoned for any reason other than
          weather (which may include but is not limited to: dangerous or
          unplayable wicket or outfield; pitch vandalism; strike or boycott;
          crowd protests/violence; stadium damage; acts of terrorism; and acts
          of God), It reserves the right to void all bets, except for those on
          markets that have been unconditionally determined.
          <br />
          If the match is not scheduled to be completed within five days after
          the original scheduled completion date, then all bets on markets for
          this event will be void, except for bets on any markets that have been
          unconditionally determined.
          <br />➢ Limited Over matches
          <br />
          If a match is declared "No Result'', bets will be void on all markets
          for the event except for those markets which have been unconditionally
          determined or where the minimum number of overs have been bowled as
          laid out in the market specific information.
          <br />
          In the event of a new toss taking place on a scheduled reserve day for
          a limited over match all bets that were placed after 30 minutes before
          the original scheduled start of play on the first day will be made
          void. This rule relates to all markets except those that have been
          unconditionally determined (e.g. in the win the toss and toss
          combination markets).
          <br />➢ Super Over rule
          <br />
          Which team will win this Super Over? This market will be suspended on
          site and activated once Betfair are aware a Super Over is to be
          played. The market will be turned in-play at the start of the Super
          Over. This market will not be actively managed therefore it is the
          responsibility of all customers to manage their positions. This market
          will be settled based on the number of runs scored by each team in the
          initial Super Over. For the avoidance of doubt, if scores are tied at
          the completion of both innings in the Super Over then the market will
          be settled as a Dead Heat except in cases where more than one Super
          Over is played, where it will be settled on the winner of the final
          over. Any tie breaker that may be used to determine a winner including
          but not limited to higher number of boundaries, higher number of
          sixes, losing fewer wickets, coin toss etc. do not count for the
          purposes of this market.
          <br />
          8. Cycling
          <br />
          Where there is a presentation ceremony, markets will be settled on the
          official result of the relevant governing body at the time of the
          ceremony, regardless of any subsequent disqualification or amendment
          to the result.
          <br />
          If there is no presentation ceremony, outcomes will be determined in
          accordance with the official result of the relevant governing body,
          regardless of any subsequent disqualification or amendment to the
          result (except if an amendment is announced within 24 hours of the
          initial settlement of the relevant market in order to correct an error
          in reporting the result).
          <br />
          9. Darts
          <br />
          If a match is not completed for any reason then bets on the match odds
          market will be void.
          <br />
          If a match is not completed for any reason then bets on 'any correct
          score' or 'next leg/game/set' market will be void unless the market
          has been unconditionally determined.
          <br />
          If a match is not completed for any reason then bets on any handicap
          market will be void unless the market has been unconditionally
          determined.
          <br />
          10. Financial markets
          <br />➢ Indices
          <br />
          Intraday, Midday and PM markets will be settled on the first Bloomberg
          price stamp past the market closure time regardless of any subsequent
          amendment to the result (except if an amendment is announced within 24
          hours of the initial settlement of the relevant market in order to
          correct an error in reporting the result).
          <br />
          All Daily markets, weekly markets and end of day Intraday markets will
          be settled on the official close price following any relevant auction
          period regardless of any subsequent amendment to the result (except if
          an amendment is announced within 24 hours of the initial settlement of
          the relevant market in order to correct an error in reporting the
          result).
          <br />➢ Equities
          <br />
          All equity markets will be settled on the official close price given
          by Bloomberg regardless of any subsequent amendment to the result
          (except if an amendment is announced within 24 hours of the initial
          settlement of the relevant market in order to correct an error in
          reporting the result).
          <br />
          Should a share split be announced, the market will be determined by
          the net change in value of the new share price at the close of the
          day&rsquo;s trading.
          <br />➢ Interest Rates
          <br />
          Interest rate markets will be settled once the result is regardless of
          any subsequent amendment to the result (except if an amendment is
          announced within 24 hours of the initial settlement of the relevant
          market in order to correct an error in reporting the result).
          <br />
          11. House Price
          <br />
          For settlement purposes, the Standardized Average Price (seasonally
          adjusted) provided by HBOS will be used and markets will be settled
          once the result is regardless of any subsequent amendment to the
          result (except if an amendment is announced within 24 hours of the
          initial settlement of the relevant market in order to correct an error
          in reporting the result).
          <br />
          It will only use prices provided by HBOS. If the HBOS figures are
          discontinued or not available for any relevant period then all bets
          will be void unless stated otherwise in the Market Information.
          <br />
          All Quarterly markets may be incomplete markets and therefore
          additional "runners" may be added at any time to reflect the movements
          in house prices.
          <br />➢ Currency markets
          <br />
          All Currency markets will be settled using the first Spot price given
          by Bloomberg past the market closure time regardless of any subsequent
          amendment to the result (except if an amendment is announced within 24
          hours of the initial settlement of the relevant market in order to
          correct an error in reporting the result).
          <br />
          12. Golf
          <br />➢ General
          <br />
          Tournament bets will only be settled if the minimum number of holes
          stipulated in the Market Information has been completed.
          <br />
          Where there is a presentation ceremony, markets will be settled on the
          official result of the relevant governing body at the time of the
          ceremony, regardless of any subsequent disqualification or amendment
          to the result.
          <br />
          If there is no presentation ceremony, outcomes will be determined in
          accordance with the official result of the relevant governing body,
          regardless of any subsequent disqualification or amendment to the
          result (except if an amendment is announced within 24 hours of the
          initial settlement of the relevant market in order to correct an error
          in reporting the result).
          <br />
          If a player does not start a tournament then all bets on that player
          will be void.
          <br />
          Any player starting a tournament but withdrawing or being disqualified
          before the end of the tournament will be settled as a loser.
          <br />
          If a tournament is shortened and settles the tournament markets then
          all bets matched after the last completed round will be void.
          <br />
          In any 'to qualify' market for any tournament the winners are the
          number of golfers that qualify for the tournament under the terms set
          out in the Market Information, whether they compete in the tournament
          or not. Markets will be settled after the qualifying stage and any
          subsequent disqualification or amendment to results will not count.
          <br />
          If a Tournament/Round is restarted from the beginning, all bets placed
          after the official off time will be void, except on markets which have
          been unconditionally determined, which will stand. Bets on 2 or 3
          balls will only be void if matched after the tee time of the relevant
          2 or 3 balls.
          <br />
          If it does not suspend a tournament market and cancel unmatched bets
          at the time stated in the market rules but the market is turned
          in-play at a later time, all bets matched between the start of a
          day&rsquo;s play and the time the market is turned in-play will be
          void.
          <br />➢ Tournament match betting (i.e. 72-hole match bets)
          <br />
          If a player withdraws without playing a stroke all bets on the
          relevant market will be void.
          <br />
          If all players fail to complete a particular round for any reason the
          winner is the player with the lowest total score after the previous
          round.
          <br />
          If a player is disqualified or withdraws during any round they will be
          settled as a loser providing at least one other player completes that
          round.
          <br />
          Should all players fail to complete the first round then all bets will
          be void.
          <br />➢ Round betting markets (e.g. 2 balls, 3 balls etc.)
          <br />
          If a player withdraws without playing a stroke in that round all bets
          on the relevant market will be void.
          <br />
          If one player fails to complete a round for any reason other than a
          withdrawal or disqualification then all bets will be void except for
          those on markets which have been unconditionally determined.
          <br />
          Any player withdrawing or being disqualified having played a stroke in
          that round will be settled as a loser providing at least one other
          player completes that round.
          <br />
          Should all players fail to complete the round then all bets will be
          void.
          <br />➢ Stroke play hole-by-hole markets (i.e. performance of a named
          player on a given hole)
          <br />
          Should a hole not be completed for any reason all bets on that hole
          will be void unless the market has been unconditionally determined.
          <br />
          Markets are settled on completion of the hole and any subsequent
          penalties or disqualification will not be taken into account.
          <br />➢ Match play hole-by-hole markets (i.e. performance of players
          against each other on a given hole)
          <br />
          With the exception of a player or team conceding a hole (where they
          are deemed a loser), if a hole is not completed by any player or team
          (other than for withdrawal or disqualification) all bets on that hole
          will be void unless the market has been unconditionally determined.
          <br />
          Any player or team withdrawing or being disqualified having played a
          stroke on that hole will be settled as a loser providing at least one
          other player completes that hole.
          <br />
          If any player or team does not play a stroke on a hole all bets will
          be void.
          <br />
          Markets are settled on completion of the hole and any subsequent
          penalties or disqualification will not be taken into account.
          <br />
          13. Greyhound racing
          <br />➢ General
          <br />
          All bets (excluding those struck on ante-post and Australian licensed
          markets) are placed on trap numbers. Greyhound names are displayed for
          information purposes only.
          <br />
          Markets will be determined according to the official result at the
          time the track gives the result green light status, either in the form
          of an announcement or by display. Subsequent disqualifications,
          appeals or amendments to the result will be disregarded.
          <br />
          If a non-runner or reserve runner is declared, then all bets prior to
          the update of the market on It will be void and all unmatched bets
          including &lsquo;Take SP&rsquo; and &lsquo;keep&rsquo; bets will be
          cancelled (except for certain SP bets as set out in Paragraph 10.5 of
          Part B above).
          <br />
          If there are no finishers in any race or any race is declared void
          before the official result is declared then all bets will be void.
          <br />
          If the scheduled venue is changed after the market has been loaded by
          It, all bets will be void.
          <br />
          In ante-post markets, all bets on individual greyhounds stand whether
          the greyhound runs or not. All ante-post bets will be void if the
          competition is abandoned or the venue is changed.
          <br />➢ Trap Challenge
          <br />
          Bets on 'Trap challenge' markets are based on which trap results in
          the most winners during a race meeting, unless stated otherwise in the
          Market Information.
          <br />
          These markets are unaffected by any changes due to non-runners or
          reserve runners.
          <br />
          If a race results in a dead heat each trap will receive a half-win,
          with a 3-way dead heat giving a one third-win, and so on.
          <br />
          Standard dead heat rules apply if two or more traps have an equal
          number of winners at the end of both meetings.
          <br />
          If a whole meeting is abandoned all bets are void. However, if at
          least one race has been completed then bets on these markets will
          stand.
          <br />➢ Multi-trap or Multiplied Trap Numbers
          <br />
          'Multi-trap' or 'multiplied trap numbers' bets are on the cumulative
          sum of the winning trap multiplied by the second trap for each race
          during a race meeting, unless stated otherwise in the Market
          Information. If the cumulative sum is not a round number then that sum
          shall be rounded up to give the applicable cumulative sum.
          <br />
          These markets are unaffected by any changes due to non-runners or
          reserve runners.
          <br />
          If a race is cancelled, void or abandoned or if there is only one
          finisher in a race, an eight runner-greyhound races will be allocated
          20 points and all other races will be allocated 12 points.
          <br />
          If a whole meeting is abandoned all bets are void however if at least
          one race has been completed then bets on these markets will stand with
          the remaining races allocated 20 points for eight runner greyhound
          races and 12 points for all other races.
          <br />
          Re-run races will be treated as cancelled races.
          <br />
          If there is a dead heat for the winner between two greyhounds then to
          calculate the applicable multi-trap number for that race the trap
          numbers for the greyhounds involved in the dead heat will be
          multiplied by each other to give the applicable multi-trap number.
          <br />
          If there is a dead heat for the winner between three or more
          greyhounds then to calculate the applicable multi-trap number for that
          race the trap numbers for the greyhounds involved in the dead heat
          will be added up and divided by the number of greyhounds involved in
          the dead heat and the resulting number will be multiplied by itself to
          give the applicable multi-trap number (even if not a round number). By
          way of example: traps 1, 3 and 6 dead heat for first. Add these
          numbers up gives 10; divide this number by the number of greyhounds in
          the dead heat (3) which gives a resulting number of 3.33; multiply
          3.33 by itself to give 11.11.
          <br />
          If there is a dead heat for the runner up between two or more
          greyhounds then to calculate the applicable multi-trap number for that
          race the trap numbers for the greyhounds involved in the dead heat
          will be added up and divided by the number of greyhounds involved in
          the dead heat and the resulting number will be multiplied by the
          number of the winner to give the applicable multi-trap number (even if
          not a round number). By way of example: trap 1 wins and traps 3 and 6
          dead heat for second. Adding the numbers of the dead heating runners
          up gives 9; divide this number by the number of greyhounds in the dead
          heat (2) which gives a resulting number of 4.5; multiple 4.5 by the
          winning number (1) to give 4.5.
          <br />➢ Winning Distances
          <br />
          'Winning distances' bets are on the sum of the winning distances for
          all races during a race meeting, unless otherwise stated in the Market
          Information.
          <br />
          The maximum winning distance in any race is 10 lengths.
          <br />
          If there is only one finisher in any race the winning distance will be
          deemed to be 10 lengths.
          <br />
          If a race is cancelled, void or abandoned the winning distance will be
          deemed to be 2 lengths.
          <br />
          Re-run races will be treated as cancelled races.
          <br />
          For distances below half a length, the following scale will be
          applied: Short-head 0.1; Head 0.2; Neck 0.3.
          <br />
          The sum of all the winning distances will be rounded to the nearest
          whole number at the end of the meeting (rounded up, if half) and bets
          will be settled on this result.
          <br />
          If a whole meeting is abandoned all bets are void. However, if at
          least one race has been completed then the remaining races are
          allocated 2 lengths each and bets will stand.
          <br />➢ Match Bets
          <br />
          'Match Bets' for a race are determined by the greyhound with the
          highest finishing position in that race. If neither greyhound finishes
          the race bets are void. If only one of the greyhounds finishes the
          race, that greyhound will be deemed the winner. If a non-runner or
          reserve runner is declared then all bets will be void.
          <br />
          'Match Bets' for progress in a competition are determined by the
          greyhound which qualifies to the furthest round (whether it runs in
          the further round or not). If the greyhounds concerned fail to qualify
          in the same round of the competition then the market will be settled
          as a dead heat, irrespective of their finishing positions in their
          individual heats.
          <br />➢ Reverse Forecasts
          <br />
          'Reverse Forecast' markets are determined by those greyhounds placed
          first and second in a race.
          <br />
          If a dead heat affects the reverse forecast then dead heat rules
          apply.
          <br />➢ Place markets
          <br />
          The number of winners in 'to be placed' markets is as set out in the
          Market Information and is determined with reference to the number of
          runners when the market is loaded.
          <br />
          Once opened, the number of winners in 'to be placed' markets (as set
          out in the Market Information) will not be affected by further
          non-runners. If the number of potential winners is equal to or is
          greater than the number of runners, all bets in this market will be
          void.
          <br />➢ To Reach the Final and To Qualify markets
          <br />
          'Reach the Final' markets shall be determined by the first six
          greyhounds who qualify from the Semi Finals of the competition and be
          settled as such regardless of whether they go on to run in the final
          or not.
          <br />
          'To qualify' markets shall be determined by the greyhounds that
          qualify in the relevant heats irrespective of whether they run in the
          next round or not.
          <br />
          The 'reach the final' or 'to qualify' markets will be settled after
          the qualifying stages and any subsequent disqualification or amendment
          to results will not count.
          <br />➢ Ante-post
          <br />
          If a greyhound listed in an ante-post market dies, it will use the
          available information to determine the time of the greyhound's death.
          It will then, acting reasonably, determine whether the greyhound was a
          'material runner' in the market. In determining whether a greyhound is
          a material runner, it will look at the general price of the greyhound
          immediately before the greyhound's death, in the market and in the
          wider betting market. Broadly, it will consider a greyhound to be a
          material runner if it is deemed to have an approximate chance of
          winning of 8-10 per cent or better.
          <br />
          If It determines that the greyhound was a 'material runner', then all
          bets struck in the market (on all selections) between the time of
          death and the suspension of the market will be void and all unmatched
          bets will be cancelled before the market is reopened.
          <br />
          If It determines that the greyhound was not a 'material runner', then
          just bets struck on the greyhound in question between the time of
          death and the suspension of the market will be void and unmatched bets
          will not be cancelled before the market is reopened.
          <br />
          Bets matched on greyhounds after they have forfeited their entry at a
          particular entry stage or where they have not qualified by the
          applicable qualifying date will be voided unless the race in question
          has a subsequent supplementary entry stage.
          <br />
          Where an event is postponed or rescheduled to another day at the same
          venue then ante-post bets will stand unless entries are reopened in
          which case all bets will be void.
          <br />➢ Australian Specific Non-Runner Rules
          <br />
          Notwithstanding any of the above, the following rules apply to
          declared non-runners in Australian greyhound markets.
          <br />
          If a greyhound becomes a notified non-runner after the market is
          loaded but prior to the commencement of the race it will be removed
          and all bets on the market, matched prior to the update of the market
          will be voided.
          <br />
          If, following the completion of a race, the stewards declare a
          greyhound a non-runner, it will resettle the market and will void all
          bets that were placed on that runner only. It will then apply a
          reduction factor to all bets placed on the winner (or place getters in
          the case of place markets) based on that runner&rsquo;s weighted
          average price.
          <br />
          14. Horseracing
          <br />➢ General
          <br />
          All individual race markets will be determined according to the
          official result at the time of the 'weigh-in' announcement (or
          equivalent). Subsequent disqualifications, appeals or amendments to
          the result will be disregarded.
          <br />
          If a race is abandoned or otherwise declared void, or in the event of
          a walkover, all bets on that race will be void.
          <br />
          If the scheduled venue is changed after the market has been loaded,
          all bets will be void.
          <br />
          Where a race does not take part on its scheduled day, all bets will be
          void.
          <br />
          If a scheduled surface type is changed (e.g. turf to dirt) all bets
          will stand.
          <br />
          Horseracing Exchange Multiples are based on &lsquo;day of the
          race&rsquo; markets (and not ante-post markets). Horseracing ante-post
          rules do not therefore apply in relation to horseracing Exchange
          Multiples.
          <br />➢ Ante-post
          <br />
          Subject to the points below, in ante-post markets, all bets on an
          individual horse stand whether the horse runs or not.
          <br />
          For GB and Irish racing: if a horse is balloted out, all bets on that
          horse will be void.
          <br />
          If a horse listed in an ante-post market dies, it will use the
          available information to determine the time of the horse's death. It
          will be acting reasonably, determine whether the horse was a 'material
          runner' in the market. It will undertake a similar determination in
          cases where it is advised through official channels that a runner has
          been formally scratched from an ante-post race. In determining whether
          a horse is a material runner, it will consider the general price of
          the horse immediately before the horse's death (or immediately before
          being advised it has been scratched), in the market and in the wider
          betting market. Broadly, it will consider a horse to be a material
          runner if it is deemed to have an approximate chance of winning of
          8-10 per cent or better.
          <br />
          If it determines that the horse was a 'material runner', then all bets
          struck in the market (on all selections) between the time of death (or
          the time that the formal scratching notification was created) and the
          suspension of the market will be void and all unmatched bets will be
          cancelled before the market is reopened.
          <br />
          If it determines that the horse was not a 'material runner', then just
          bets struck on the horse in question between the time of death (or the
          time that the formal scratching notification was created) and the
          suspension of the market will be void and unmatched bets will not be
          cancelled before the market is reopened.
          <br />
          Bets matched on horses after they have forfeited their entry at a
          particular entry stage or where they have not qualified by the
          applicable qualifying date will be voided unless the race in question
          has a subsequent supplementary entry stage.
          <br />
          Please be aware that runners who have not been entered at the various
          entry stages may be removed from relevant race markets and all matched
          customer bet prices set to 1.0 even if there are later supplementary
          stages. Should it appear likely that a specific runner may actually be
          supplemented into the race this runner will be reinstated with all
          matched customer bets set back to the original prices.
          <br />
          No Non-Runner Rule' reductions are made to ante-post bets.
          <br />
          All relevant bets will be void where an event is: abandoned and not
          rescheduled; or postponed and rescheduled to another venue; or
          postponed and rescheduled to another day at the same venue with
          entries for the race being reopened or reverting back to a previous
          entry or declaration stage provided any additional horses are entered
          or re-entered and such entries or declarations are considered, in
          absolute discretion, material to the betting on the race. For the
          avoidance of doubt, where a race is postponed and rescheduled to
          another day at the same venue, relevant bets will stand where
          entries/declarations at the time of postponement remain unaltered or
          include any non-material addition(s) prior to the rescheduled race.
          <br />➢ Place Terms for Exchange markets
          <br />
          The number of winners in 'to be placed' markets is as set out in the
          Market Information and is determined with reference to the number of
          runners when the market is loaded.
          <br />
          Once opened, the number of winners in 'to be placed' markets will not
          be affected by further non-runners.
          <br />
          If the number of winners stated in the Market Information is equal to
          or is greater than the number of runners, all bets in this market will
          be void.
          <br />
          If the number of placed horses is less than the number of potential
          winners listed in the Market Information, the winners will only be the
          placed horse or horses.
          <br />➢ EW markets
          <br />
          The place portion of any "Each Way" bet will be settled according to
          the number of 'places' and at the fraction of the win portion profit
          stated in the market information.
          <br />
          For example: &ldquo;EW Terms: 1/5th odds, 3 places&rdquo;. That
          &ldquo;1/5th&rdquo; is applied to the traditional or fractional odds,
          which are 1 less than decimal odds, so the calculation of the Place
          odds corresponding to Win odds of 8.0 in this market is ((8.0 &ndash;
          1) / 5) + 1 = 2.4.
          <br />
          Once opened, the number of places in 'EW&rdquo; markets will not be
          affected by further non-runners unless the number of
          &lsquo;places&rsquo; which are offered on the &lsquo;Each Way&rsquo;
          market is equal to or exceeds the number of runners in which case, the
          place portion of any EW bet will be voided at settlement.
          <br />➢ Winning Distance Bets
          <br />
          Unless stated otherwise in the Market Information, winning distances'
          bets are on the sum of the winning distances from each race on one
          particular day at a race meeting.
          <br />
          The winning distance in a race will be the officially declared
          distance between the first two horses past the post. However, if
          either the first or second horse past the post is disqualified
          because: (i) of an incorrect weight carried; (ii) they have taken the
          wrong course; (iii) the jockey of either horse fails to weigh in, or
          weighs in light after the race; then in each case the winning distance
          shall be that between the first and second horse under the official
          result.
          <br />
          For the purpose of this bet, the maximum distance on any individual
          race will be 12 lengths for Flat races and 30 lengths for National
          Hunt races (which includes National Hunt Flat Races) and these
          distances will be applied where only one horse finishes a race. For
          distances below half a length, the following scale will be applied:
          <br />
          Nose: 0.05 length
          <br />
          Short-head: 0.1
          <br />
          Head: 0.2
          <br />
          Short-neck: 0.25
          <br />
          Neck: 0.3
          <br />
          If a meeting has three or more races abandoned or declared void, then
          all 'winning distance' bets will be void, unless the entire market has
          been unconditionally determined.
          <br />
          When only one or two races are abandoned or declared void, then
          'winning distance' bets will be settled with a default distance used
          for each abandoned or void race. The default distances are 2 lengths
          for Flat races and 10 lengths for National Hunt races.
          <br />
          In the case of a walkover the following distances will be applied:
          <br />
          Flat: 5 lengths
          <br />
          National Hunt: 12 lengths
          <br />➢ Non-Runner Rule
          <br />
          Non-runner rule relates to the adjustment of odds on bets already
          matched when a horse in a race is declared a non-runner. In order to
          make the adjustment applies a reduction factor to the remaining
          runners. The reduction factor allocated to a non-runner is a
          calculation (the details of which are described below) of that horse's
          chances of winning (or being placed, etc. as appropriate) and is
          applied to bets already matched on the other runners in the relevant
          market or markets.
          <br />
          Any horse listed when the relevant market is loaded which does not
          subsequently come under starter's orders is deemed to be a non-runner.
          <br />
          When the market is loaded each horse is given a 'reduction factor',
          based on a forecast price, which is expressed as a percentage. These
          reduction factors may be updated periodically at the discretion based
          on trading in the market, but after approximately 15 minutes
          (approximately 5 minutes for Australian and US markets) from the
          scheduled 'off' time of a given race, they will be updated only in
          exceptional circumstances.
          <br />
          Reductions will be made to both win and place markets but applied
          differently (as described below), and horses will have a different
          reduction factor for each market.
          <br />
          As soon as becomes aware that a horse is an official non-runner or a
          highly likely non-runner, following a statement to the press from
          connections, the following will happen:
          <br />
          All matched bets on that horse will be void and the horse will be
          removed from the market.
          <br />
          In the win market: if the reduction factor of the non-runner is 2.5%
          or greater, the traded price of all the matched bets on the remaining
          horses will be reduced by an amount equal to the non-runner's final
          reduction factor and all the unmatched offers to lay will be
          cancelled. If the non-runner's reduction factor is less than 2.5%,
          reductions will not be applied and unmatched bets will not be
          cancelled.
          <br />
          In the place market, the reduction factor of all non-runners will be
          applied (even if less than 2.5%) and the potential winnings in
          relation to matched bets on the remaining horses will be reduced by an
          amount equal to the non-runner's final reduction factor. Only if the
          non-runner's reduction factor is 4.0% or greater will all the
          unmatched offers to lay be cancelled.
          <br />
          All the reduction factors on the remaining horses will be adjusted to
          reflect their improved chance of winning.
          <br />
          Reduction factors are not applied to bets which are struck in-play.
          However, if a market is turned in-play prematurely by error (or, for
          example, there is a false start), all bets matched during this time
          will be subject to any later reduction factor, provided the market is
          turned out of play before the race commences. In the event of a late
          withdrawal, it reserves the right to remove the runner after
          completion of the race. In this case only those bets matched prior to
          the off will be affected by a reduction factor.
          <br />
          In the event of a non-runner being removed from a race in error or
          following incorrect information regarding a runner&rsquo;s
          participation, it will reinstate both the runner and all previously
          matched bets associated with that runner. All bets made between the
          time of withdrawal and reinstatement will be void in both the place
          market and the win market. The reduction factor applied to matched
          bets at the time of withdrawal will be reversed and the original
          prices will become valid.
          <br />
          Any non-runners will be removed from the relevant markets in the order
          in which they are brought to attention. If It becomes aware of more
          than one non-runner at the same time, it will remove the non-runners
          from the relevant markets in race card order.
          <br />
          If a runner is not included in a market because of an error or because
          of incorrect information regarding a runner&rsquo;s participation, It
          reserve the right to introduce the missing runner into the market at
          any time prior to settlement (even after the race has been run),
          provided that It has determined that the missing runner is not a
          material runner (i.e. a selection with a reduction factor of approx.
          2.5% or less in the win market). In such circumstances, all pre-play
          unmatched and matched bets will stand, however if the runner is not
          introduced before the start of the race, all in-play bets will be
          void. However, if the missing runner is deemed to be a material
          runner, then the malformed market will be void and a new market will
          be loaded where possible.
          <br />➢ How the Reductions are applied for Exchange markets
          <br />
          In the win market, reductions will be made on the traded price.
          <br />
          For example: if the non-runner's final reduction factor is 25% the
          traded price on all previously matched bets on other horses will be
          reduced by 25% - traded price of 8.0 would become 6.0 etc. And these
          might be further reduced if another horse is subsequently declared a
          non-runner.
          <br />
          In the EW Market, reductions will be made on the traded win price. The
          advertised place terms will then apply to the revised win prices.
          <br />
          For example: if the non-runner's final reduction factor is 25% the
          traded price on all previously matched bets on other horses will be
          reduced by 25% - - traded price of 8.0 would become 6.0. If each Way
          terms were 1/5th odds for 3 places, the corresponding price for the
          Place portion of the bet would reduce from 2.4 to 2.0.
          <br />
          In the place market, reductions will be made to the potential winnings
          on the bet only, and not the traded price.
          <br />
          For example: if the non-runner's final reduction factor is 25% the
          potential winnings on all previously matched bets on the other horses
          will be reduced by 25% - a traded price of 8.0 would become 6.25. For
          example, a &pound;10 bet on a horse to be placed at a traded price of
          8.0 would provide winnings of &pound;70. If there is a non-runner with
          a reduction factor of 25% in the race, that factor will be applied to
          the &pound;70 of potential winnings leaving potential winnings of
          &pound;52.50. Therefore, the revised traded price will be 6.25.
          <br />
          The traded price may be further reduced if any other horse(s) is
          subsequently declared a non-runner, however odds cannot be reduced
          below 1.01.
          <br />
          Reserves: A reserve runner may appear in the relevant markets but will
          have a non-applicable reduction factor until It has received
          confirmation that it is a confirmed runner, in which case an
          applicable reduction factor may apply to it.
          <br />
          For the avoidance of doubt, any reduction factor applicable to a
          non-runner replaced by a reserve, will be applied to all bets struck
          on the relevant markets, prior to the removal from those markets of
          such non-runner. Likewise, should a reserve runner become a confirmed
          runner but subsequently become a non-runner, any reduction factor
          applicable to such non-runner will be applied to all bets struck on
          the relevant markets, prior to the removal from those markets of such
          non-runner.
          <br />➢ Additional rules
          <br />
          Card numbers are posted as a guide only: bets are placed on a named
          horse.
          <br />
          Horses will not be coupled.
          <br />
          Where any horse(s) runs for purse money only it is deemed a non-runner
          for betting purposes. Should this result in the number of possible
          winners stated in the relevant Market Information being equal to or
          greater than the number of runners in the relevant market, all bets in
          the market will be void.
          <br />
          15. Ice Hockey
          <br />
          All bets on "Regular Time" markets will be settled on the result at
          the end of regular time, excluding overtime. All bets on " Moneyline "
          markets will be settled at the result at the end of regular time
          including "overtime and any shootouts that may be played".
          <br />
          'Puck Line' markets (i.e. handicap markets for NHL (National Hockey
          League) matches) will be settled on the final result including any
          overtime and any shootouts that may be played. All 'handicap' markets
          on other matches will be settled on the result at the end of regular
          time, excluding overtime.
          <br />
          If a match does not start on the scheduled start date and is not
          completed within three days of the scheduled start date, all bets will
          be void except for those on markets which have been unconditionally
          determined.
          <br />
          If a match starts but is later abandoned or postponed then, within
          three days of the scheduled start date, (a) at least 55 minutes of
          play must have elapsed in any match; or (b) an official result must be
          'called' by the relevant governing body; otherwise all bets will be
          void, except for those which have been unconditionally determined. In
          these instances, if the scores are tied then for " Moneyline " matches
          (where no tie is offered) dead heat rules will apply to bets on the
          outright match winner market.
          <br />
          16. Rowing
          <br />
          If a crew or individual starts a race but does not complete it then
          they will be deemed a loser providing at least one other crew or
          individual completes the race. If no crew or individual completes a
          race then all bets will be void.
          <br />
          If a regatta is cancelled for any reason, all bets will be void,
          except those on markets which have been unconditionally determined.
          <br />
          Where there is a presentation ceremony, markets will be settled on the
          official result of the relevant governing body at the time of the
          ceremony, regardless of any subsequent disqualification or amendment
          to the result.
          <br />
          If there is no presentation ceremony, outcomes will be determined in
          accordance with the official result of the relevant governing body,
          regardless of any subsequent disqualification or amendment to the
          result (except if an amendment is announced within 24 hours of the
          initial settlement of the relevant market in order to correct an error
          in reporting the result).
          <br />
          17. Rugby Union and Rugby League
          <br />
          'Tournament points' and 'tournament tries' bets will apply to all
          playing time, including any extra-time in any match where an official
          result is declared.
          <br />
          For 'time of first try' bets, if the try is scored in the second half,
          the first half is deemed to have lasted 40 minutes, regardless of
          stoppage-time. If no try is scored, or the try is scored in second
          half stoppage-time or extra-time, the result is 80.
          <br />
          For the purpose of markets involving tries, penalty tries will count
          with the exception of 'first individual try scorer' markets where
          penalty tries will not count.
          <br />
          If a match starts but is abandoned before its completion, all bets
          will be void unless an official result is declared by the applicable
          governing body. Where an official result has been declared by the
          official governing body, that official result will govern match and
          handicap market settlement but all other markets will be void unless
          their result has already been determined at the point of abandonment
          (i.e. at the point of abandonment, it would not have been possible for
          the outcome of the bet to change had the match continued to its
          natural conclusion). By way of example, if 37 points have been scored
          at the time a match is abandoned: (i) a bet placed on 35 points or
          more to be scored would be settled as a winning bet, (ii) a bet placed
          on 30-35 points to be scored would be settled as a losing bet and
          (iii) a bet placed on 40-45 points to be scored would be void. In such
          circumstances, bets on last try scorer / team to score last / team to
          score last try / last scoring play would all be void.
          <br />
          18. Snooker and Pool
          <br />
          In the event of a match starting but not being completed, the player
          progressing to the next round will be deemed the winner (or in the
          case of the final the player declared the winner).
          <br />
          If a match is not completed for any reason then bets on 'any correct
          score' or 'next frame' market will be void.
          <br />
          If a match is not completed for any reason, bets on any handicap
          market will be void unless the market has been unconditionally
          determined.
          <br />
          19. Soccer
          <br />
          If it does not suspend a market on time for the occurrence of a
          Material Event, it reserves the right to void bets unfairly matched
          after the Material Event has occurred. Voiding of these bets may take
          place during the event or retrospectively once a game is completed.
          <br />
          If a match has not started (or believes that a match will not have
          started) by 23:59 (local time) on its scheduled start date, then all
          bets will be void unless it has knowledge that the match has been
          rescheduled to be played within three days of its original start date.
          <br />
          If a match starts but is later abandoned or postponed and believes
          that the match will not have been completed by 23:59 (local time) on
          its scheduled start date, then all markets, with the exception of any
          unconditionally determined markets, will be void unless it has
          knowledge that the match has been rescheduled to be played within
          three days of its original start date. If it does have knowledge that
          the game will be played within three days and the game is played
          within three days, then all bets will stand except if the match is
          restarted from the beginning. If the match is restarted from the
          beginning then all bets matched before the market went in-play will
          stand, but any bets placed in-play will be void, except for any bets
          placed in-play on markets which have been unconditionally determined,
          which will stand.
          <br />
          For Friendly matches, all bets apply to the full duration of play
          according to the match officials, plus any stoppage time. If a
          friendly match starts but is later abandoned or postponed and is not
          completed (i.e. the full duration of play according to match
          officials, plus any stoppage time) within three days of the scheduled
          start date, all bets will be void except for those on markets which
          have been unconditionally determined. In the case of ambiguity over
          the official result from match officials, the outcome will be
          determined (acting reasonably) using information from independent
          sources.
          <br />
          If an official fixture lists different team details to those listed
          (for example, the team name, reserves, age group, gender, etc.), then
          all bets matched on the affected markets will be void. In all other
          cases, bets will stand (including instances where a team name is
          listed without specifying the term 'XI' in the name). If an official
          fixture is shown on the website under an incorrect competition name,
          then it reserves the right to void all bets matched on the affected
          markets.
          <br />
          If a team is relegated from a league because, at the end of a season,
          it has finished within the relegation positions which are relevant to
          that league (i.e. usually any of the bottom three league positions),
          bets on that team to be relegated will be settled as winning bets. If
          a team is otherwise disqualified, thrown out or removed from a league
          (i.e. in circumstances other than those where it has finished the
          season within the relevant relegation positions): (i) if such team is
          disqualified, thrown out or removed from the league before the
          relevant season has started, all bets on the affected market will be
          void (and a new market will subsequently be loaded) and (ii) if such
          team is disqualified, thrown out or removed from the league after the
          relevant season has started (or a determination is made by the
          applicable governing body, during the season, that the team will be
          thrown out or removed from the league following the season&rsquo;s
          conclusion), all bets on the affected team will be void. For the
          avoidance of doubt, if a points deduction is imposed on a team such
          that it finishes the season within the relegation places which are
          relevant to the applicable league, bets on that team to be relegated
          will be settled as winning bets.
          <br />
          The relevant season will be deemed to have started once the first
          league game has been played. For the purposes of this rule, markets
          relating to individual matches will not be deemed to be "affected
          markets".
          <br />
          'Shirt numbers' bets will refer to the shirt number allocated at the
          start of the match. 'Shirt numbers' bets will include own-goal
          scorers. Any player whose shirt bears no number will be allocated the
          number 12.
          <br />
          For 'time of first goal' bets (i.e. "First Goal Odds" markets), the
          first half is deemed to last 45 minutes, regardless of stoppage time.
          Also for these markets, please note that the "0 - 10 Minutes"
          selection covers the first 10 minutes of the match. In other words, it
          runs from 0:00 until just before the clock hits 10:00. The "11 &ndash;
          20 Minutes" selection runs from 10:00 until just before the clock hits
          20:00. The same principle applies to each of the other selections in
          this market.
          <br />
          For 'top goal scorer' markets only the goals scored in the league or
          competition stated in the Market Information count. For example, if a
          player joins a club mid-season any goals scored in a different league
          will not count, however goals scored for a different club in the same
          league will count. Own goals will not count.
          <br />
          In markets which relate to the number of incidents to occur, such as
          'number of corners', these will be determined on the basis of the
          number taken, rather than awarded.
          <br />
          For markets that relate to the number of bookings given, the number of
          corners taken, any goal scorer or the time of a particular goal, the
          result will be determined (acting reasonably) using information from
          independent sources. In such cases, if any new information comes into
          the public domain within 48 hours of settlement, then it shall (acting
          reasonably) determine either: (i) whether the market should be
          reinstated or resettled in light of this new information; or (ii) to
          wait for further information before deciding whether to reinstate or
          resettle the market. Except it has announced that it is waiting for
          further information, any information that comes into the public domain
          more than 48 hours after a market has been settled shall not be
          considered (regardless of whether or not such information may have led
          to a different result).
          <br />
          20. Swimming
          <br />
          Where there is a presentation ceremony, markets will be settled on the
          official result of the relevant governing body at the time of the
          ceremony, regardless of any subsequent disqualification or amendment
          to the result.
          <br />
          If there is no presentation ceremony, outcomes will be determined in
          accordance with the official result of the relevant governing body,
          regardless of any subsequent disqualification or amendment to the
          result (except if an amendment is announced within 24 hours of the
          initial settlement of the relevant market in order to correct an error
          in reporting the result)
          <br />
          21. Tennis
          <br />
          If a player or pairing retires or is disqualified in any match, the
          player or pairing progressing to the next round (or winning the
          tournament in the case of a final) will be deemed the winner. However,
          if less than one set has been completed at the time of the retirement
          or disqualification then all bets relating to that individual match
          will be void.
          <br />
          All bets relating to the number of occurrences of certain events
          within a tournament will be void if the tournament is reduced in
          length, postponed or cancelled, except for those on markets which have
          been unconditionally determined.
          <br />
          All bets will stand regardless of changes to scheduled venues,
          including any changes to a different type of surface.
          <br />
          If the scheduled duration of a match is reduced or increased in the
          number of games/sets required to win, all bets will be void except for
          those on markets which have been unconditionally determined. Please
          note that this does not apply to &lsquo;Match Odds&rsquo; or
          &lsquo;Set Winner&rsquo; markets on Davis Cup matches or &lsquo;dead
          rubber&rsquo; matches that have been shortened from five sets to three
          sets after the market has been loaded, provided that the match has
          been shortened in accordance with the competition&rsquo;s rules.
          <br />
          Where markets are offered on individual games or sets within a match,
          a retirement or disqualification during a game or set will render bets
          on that game or set market and all individual game or set markets void
          except those on markets which have been unconditionally determined.
          <br />
          22. Winter sports (which may include, amongst others, Alpine Skiing,
          Biathlon, Cross-Country Skiing and Ski-Jumping)
          <br />
          Where there is a presentation ceremony, markets will be settled on the
          official result of the relevant governing body at the time of the
          ceremony, regardless of any subsequent disqualification or amendment
          to the result.
          <br />
          If there is no presentation ceremony, outcomes will be determined in
          accordance with the official result of the relevant governing body,
          regardless of any subsequent disqualification or amendment to the
          result (except if an amendment is announced within 24 hours of the
          initial settlement of the relevant market in order to correct an error
          in reporting the result).
          <br />
          Unless stated otherwise in the Market Information, if an event is
          abandoned, postponed or cancelled, all bets will be void unless (a)
          the event is completed at the same venue within 7 days of the official
          scheduled completion date; or (b) a result is 'called' by the relevant
          governing body.
          <br />
          The General Rules will apply for 'match bets'. However, in respect of
          "Nordic Combined" if all competitors involved in the match bet do not
          start both sections of the event (ski jumping and cross country) then
          bets will be void.
        </p>
      </div>
    </>
  );
};

export default RulesRegulation;
